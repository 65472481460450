@import "~antd/dist/antd.css";

#root {
  height: 100%;
}

.App {
  /* text-align: center; */
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.header-top-left-logo {
  width: 100%;
  text-align: center;
  padding: 5px;
}

.header-top-left-logo img {
  height: 50px !important;
}

.ant-menu-inline-collapsed .ant-menu-item-icon {
  vertical-align: inherit !important;
}

.page-title .add-event {
  padding: 5px 30px;
}

.page-title-head {
  font-size: 1.2rem;
  font-weight: bold;
}

.action-button {
  padding: 5px 15px;
}

.notification-popover {
  min-width: 350px;
  max-width: 350px;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  width: auto;
}

.notification-popover1 {
  max-height: 90vh;
  overflow-y: auto;
}

.notification-popover .ant-popover-title {
  padding: 10px 16px 10px;
}

.ant-picker-input > input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input[disabled],
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.75) !important;
}

.styled-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 0px 5px 5px 5px;
  opacity: 1;
  padding: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.name_nowrap .ant-table-tbody > tr > td:first-child {
  white-space: nowrap;
}

.name_nowrap .ant-table-thead > tr > th:last-child {
  min-width: 95px;
}

.ant-form-item-label > label {
  color: #5d5f68;
  font-weight: 500;
}
@media (max-width: 321px) {
  .ant-form-item-label > label {
    height: 55px;
  }
}

.ant-input:focus,
.ant-input-focused {
  border-color: #c2fae5;
  box-shadow: 0 0 0 2px rgba(202, 240, 213, 0.33);
  border-right-width: 1px;
  outline: 0;
}

.ant-input:hover {
  border-color: #c2fae5;
  border-right-width: 1px;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #c2fae5;
  border-right-width: 1px;
  color: #000;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #c2fae5;
  border-right-width: 1px;
  z-index: 1;
}

.ant-select:hover(.ant-select-customize-input) .ant-select-focused {
  position: relative;
  background-color: #fff;
  border: 1px solid #c2fae5;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #c2fae5;
  box-shadow: 0 0 0 2px rgba(202, 240, 213, 0.33);
  border-right-width: 1px;
  outline: 0;
}

.ant-select-selector:hover {
  border-color: #c2fae5 !important; /* Change to the desired hover border color */
}

/* Appraisal css */
.common_title {
  font: normal normal medium 20px/25px Circular Std;
}

.form_builder.main_container {
  background: #f3fefa;
}

.form_builder .bg_light {
  background-color: #ffffff;
}
.ant-menu {
  border: none !important;
  border-radius: 8px !important;
}

.ant-dropdown-menu {
  padding: 0px;
  border-radius: 8px !important;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #7dd4c1 !important;
}

.ant-picker-cell.ant-picker-cell-week {
  display: none;
}

.ant-picker-body > .ant-picker-content > thead > tr > th:empty {
  display: none;
}

/* Change the text color of the date numbers in the calendar */
.fc a{
  color: #000;
}

.fc .fc-toolbar-title{
  font-size: 1.2em !important;
}

.fc .fc-button-primary {
  background-color:#C2FAE5;
  border-color: #C2FAE5;
  color: #000;
}

.fc .fc-button {
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow if present */
}

.fc .fc-button-primary:hover {
  background-color:#C2FAE5;
  border-color: #C2FAE5;
  color: #000;
}
.fc .fc-button-primary:disabled {
  background-color:#C2FAE5;
  border-color: #C2FAE5;
  color: #000;
}

.fc-event {
  background-color: transparent; /* Default event background color */
  color: #000; /* Event text color */
  border: none; /* Remove border if any */
}

.fc-daygrid-day {
  height: 100px; /* Set the desired height */
}
.fc-event:focus {
  outline: none; /* Remove the outline */
  border: none; 
}
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  border-color: transparent !important; 
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
.fc .fc-button-primary:focus {
  box-shadow: none;
}
.fc .fc-button:focus {
  box-shadow: none;
  outline: 0px;
}
