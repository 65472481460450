.divider {
    background-color: #EDEDED;
    height: 2px;
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    width: 2px;
}

.menu-item {
    background-color: transparent;
    border: none;
    border-radius: 0.4rem;
    color: #0d0d0d;
    height: 1.75rem;
    margin-right: 0.25rem;
    padding: 0.25rem;
    width: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-item svg {
    fill: currentColor;
    height: 100%;
    width: 100%;
}

.menu-item:hover, .menu-item.is-active {
    background-color: #0d0d0d;
    color: #fff;
}

.editor {
    background-color: #fff;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    color: #0d0d0d;
    display: flex;
    flex-direction: column;
    max-height: 26rem;
}

.editor__header {
    align-items: center;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
    background-color: #EDEDED;
}

.editor__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4px 11px;
    min-height: 210px;
    font-size: 14px;
    font-weight: 400;
    -webkit-overflow-scrolling: touch;
}

.editor__content[disabled] {
    background-color: #f5f5f5
}

.editor__footer {
    align-items: center;
    border-top: 3px solid #0d0d0d;
    color: #0d0d0d;
    display: flex;
    flex: 0 0 auto;
    font-size: 12px;
    flex-wrap: wrap;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    white-space: nowrap;
}

.editor__status {
    align-items: center;
    border-radius: 5px;
    display: flex;
}

.editor__status::before {
    background: rgba(13, 13, 13, 0.5);
    border-radius: 50%;
    content: " ";
    display: inline-block;
    flex: 0 0 auto;
    height: 0.5rem;
    margin-right: 0.5rem;
    width: 0.5rem;
}

.editor__status--connecting::before {
    background: #616161;
}

.editor__status--connected::before {
    background: #b9f18d;
}

.editor__name button {
    background: none;
    border: none;
    border-radius: 0.4rem;
    color: #0d0d0d;
    font: inherit;
    font-size: 12px;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
}

.editor__name button:hover {
    background-color: #0d0d0d;
    color: #fff;
}

.ProseMirror hr {
    display: none;
}

.ProseMirror-focused {
    border: none;
    outline: none;
}

.texteditor-header h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
}

.editor-textarea {
    border: 3px solid #0d0d0d;
    width: 100%;
    border-radius: 0.4rem;
}