@font-face {
  font-family: 'LatoWeb';
  src: url('assets/fonts/Lato-Regular.eot');
  src: url('assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), 
       url('assets/fonts/Lato-Regular.woff2') format('woff2'),
       url('assets/fonts/Lato-Regular.woff') format('woff'), 
       url('assets/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'LatoWeb', -apple-system, BlinkMacSystemFont, 'LatoWeb', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

.ant-layout {
  overflow-y: hidden;
  background: #F8F9FF;
}

.align-right {
  text-align: right;
}
@media (max-width: 426px) {
  .align-right {
    text-align: left;
    display: flex;
    width:100%;
    margin-top:10px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 321px) {
  .align-right {
    text-align: left;
    display: flex;
    margin-top:0px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.align-center {
  text-align: center;
}

.font-semi-bold {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.PhoneInputInput {
  height: 30px !important;
  border: 1px solid #d9d9d9 !important;
}
.PhoneInputInput:focus-visible,
.PhoneInputInput:hover,
.PhoneInputInput:active{
  border-color: #C2FAE5 !important;
  outline: #C2FAE5 !important;
}

.ant-btn-primary {
  background-color: #C2FAE5!important;
  border-radius: 5px;
  padding: 5px 20px;
  color: #000000 !important;
  border-color: #C2FAE5 !important;
}
.ant-btn {
  color: #000000 !important;
}

.ant-btn:active, .ant-btn:hover, .ant-btn:focus {
  border-color: none !important;
  background-color: #C2FAE5;
  color: #000000 !important;
}

.ant-switch-checked {
  background-color: #03C83E !important;
}
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #000000 !important;
}

::-webkit-scrollbar {
  width: 12px; 
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 6px;
  font-size: 16px;
}

.styled-cursor{
  cursor:pointer;
  color: #5d5f68;
  font-size: 16px;
}

.pdf-viewer-embed {
  background-color: transparent !important;
}

.ant-menu-dark .ant-menu-item {
  background-color: #002862; 
}
.ant-picker-now-btn{
  color: #000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
  border: 1px solid #C2FAE5 !important;
  color: #000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #000 !important;
  background: #C2FAE5 !important;
}

.ant-picker-today-btn {
  color: #000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #C2FAE5 !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color:  #000 !important;
  border-color:  #000 !important;
}

.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
  background-color:  #000 !important;
  border-color:  #000 !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color:  #000 !important;
  border: 1px solid  #000  !important;
}

.ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color: #000  !important; 
}

.ant-picker-month-btn:hover{
  color:#000 !important; 
}
.ant-picker-year-btn:hover{
  color:#000 !important; 
}
.ant-picker-decade-btn:hover{
  color:#000 !important; 
}

.ant-checkbox-wrapper {
  border-color: #000 !important;
}

.ant-checkbox-inner {
  border-color: #000 !important; /* Unchecked border color */
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.ant-checkbox:hover .ant-checkbox-inner {
  border-color:#000 !important; /* Hover border color */
}

.ant-checkbox-wrapper:focus-visible .ant-checkbox-inner, .ant-checkbox:focus-visible .ant-checkbox-inner {
  border-color: #000 !important;
}

.ant-checkbox-wrapper:checked + .ant-checkbox-inner, 
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #C2FAE5!important; /* Checked border color */
}

.ant-checkbox-checked::after {
  border: 1px solid  #C2FAE5!important;
}
.ant-checkbox {
  border: 1px solid  #C2FAE5!important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #C2FAE5 !important;
}
/* .ant-checkbox-checked .ant-checkbox-inner {
  
  border-color: red;
} */

.ant-menu-dark .ant-menu-item:hover {
  color: #000000 !important; 
}

/* Change the text color for the active menu item */
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item-selected {
  color: #000000 !important; 
}

.ant-menu-submenu-selected {
  color: #000000 !important;
}
.kXoGKB.ant-menu-item-selected .anticon, .kXoGKB.ant-menu-item-selected a, .kXoGKB.ant-menu-item-selected svg {
  color: #000000 !important;
}
.kXoGKB.ant-menu-item .anticon:hover, .kXoGKB.ant-menu-item a, .kXoGKB.ant-menu-item svg {
  color: #000000 !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #000000 !important;
}

.css-10d9dml-MuiTabs-indicator {
  background-color: #000000 !important;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #000 !important;
}
.ant-pagination-item-active {
  
  border-color: #000 !important;
}

.ant-spin-dot-item {
  background-color: #51e8b1!important;
}

.ant-spin-dot-spin {
  border-color: #000!important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000 !important; /* Change border color to red */
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #C2FAE5 !important; /* Change outline color to red */
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #000 !important;
}
.MuiRadio-root {
  color: #000 !important; 
}

.Mui-checked .MuiRadio-colorPrimary.MuiIconButton-root {
  color: #000 !important; 
}